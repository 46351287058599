<template>
    <div class="nodata">
        <span class="iconfont icon-a-iconpay_huaban1fuben10"></span>
        <p><slot></slot></p>
    </div>
</template>

<script>
export default {
    setup(props) {
        return {}
    }
}
</script>

<style lang="scss" scoped>
.nodata {
    position: fixed;
    top: h(200);
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;

    .icon-a-iconpay_huaban1fuben10 {
        font-size: 60px;
    }

    p {
        margin-top: h(10);
        font-size: 16px;
        line-height: 109.48%;
        text-align: center;
        color: #828282;
    }
}
</style>
