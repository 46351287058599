<template>
    <div class="Buy">
        <!-- <van-pull-refresh v-model="refreshing" @refresh="onRefresh"> -->
        <div v-show="dataList.length" class="data" id="BuyDrag">
            <div class="drag-container">
                <div class="item" v-for="item in dataList" :key="item.ID" @click="router.push({ path: '/BuyPage', query: { ...item } })">
                    <img v-if="item.IS_SPLIT === '1'" class="split" :src="require('@/assets/img/split.png')" />

                    <div class="left">
                        <div class="head">
                            <img :src="item.USER_PIC ? require(`@/assets/profiles/${item.USER_PIC}`) : require('@/assets/img/default.png')" />
                        </div>

                        <div class="money">
                            <p class="num">
                                <span>$</span>
                                <span>{{ item.AMT }}</span>
                            </p>

                            <div class="bank">
                                <span class="yh" v-if="item.PAY_BANK.includes('1')">银行卡</span>
                                <span class="wx" v-if="item.PAY_BANK.includes('2')">微信</span>
                                <span class="zfb" v-if="item.PAY_BANK.includes('3')">支付宝</span>
                            </div>
                        </div>
                    </div>

                    <div class="right">
                        <span>我要买</span>
                    </div>
                </div>
            </div>
        </div>
        <NoData v-show="dataList.length == 0">
            暂无更多信息记录
        </NoData>

        <!-- <img class="toTop" v-if="dataList.length" :src="require('@/assets/img/toTop.png')" @click="toTop" /> -->
        <!-- </van-pull-refresh> -->

        <van-action-sheet v-model:show="isShow" title="筛选" :close-on-click-overlay="false">
            <div class="content">
                <p>币数量</p>
                <van-field v-model="amt" type="number" placeholder="请输入" />

                <p>付款方式</p>
                <div class="pay">
                    <van-checkbox-group v-model="type" direction="horizontal">
                        <van-checkbox name="2">微信</van-checkbox>
                        <van-checkbox name="3">支付宝</van-checkbox>
                        <van-checkbox name="1">银行卡</van-checkbox>
                    </van-checkbox-group>
                </div>

                <div class="btn">
                    <van-button class="quxiao" type="primary" round size="normal" @click="isShow = false">取 消</van-button>
                    <van-button type="primary" round size="normal" @click="GetOrderList">确 定</van-button>
                </div>
            </div>
        </van-action-sheet>
    </div>
</template>

<script>
import NoData from '@/components/NoData.vue'

import { reactive, toRefs, nextTick, onMounted, inject } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { getOrderList } from '@/api'
import { toTop } from '@/assets/js/common.js'
import BScroll from '@better-scroll/core'

export default {
    components: {
        NoData
    },
    setup() {
        const state = reactive({
            time: '',
            fileConfig: '',

            refreshing: false,
            dataList: [],

            isShow: false,
            amt: '',
            type: [], // 1银行卡 2微信 3支付宝

            BScroll: null
        })

        const router = useRouter()
        const store = useStore()

        const width = inject('viewWidth', 0).value
        const height = inject('viewHeight', 0).value

        state.time = +new Date()
        state.fileConfig = store.state.fileConfig

        const GetOrderList = () => {
            state.isShow = false

            getOrderList({
                amt: state.amt, // amt 币数量
                type: state.type.join() // type 英文逗号分隔
            }).then(res => {
                state.dataList = res.data

                state.refreshing = false

                nextTick(() => {
                    state.BScroll.refresh()
                })
            })
        }
        GetOrderList()

        // WebSocket
        window.connection.off('RefreshBuyOrderList')
        window.connection.on('RefreshBuyOrderList', () => {
            if (state.time + 5000 < +new Date()) {
                GetOrderList()
                state.time = +new Date()
                console.log('RefreshBuyOrderList time: ', state.time)
            }
        })

        const onRefresh = () => {
            state.refreshing = true

            GetOrderList()
        }

        const initBScroll = () => {
            state.BScroll = new BScroll('.data', {
                click: true,
                tap: true,
                probeType: 3
            })
        }

        onMounted(() => {
            document.getElementById('BuyDrag').style.height = height - 46 + 'px'
            initBScroll()
        })

        return {
            ...toRefs(state),
            GetOrderList,
            router,
            onRefresh,
            toTop
        }
    }
}
</script>

<style lang="scss" scoped>
// 右上角筛选
.btn ::v-deep .van-button {
    width: w(120);
    border: none;
}

// 下拉加载
.Buy ::v-deep .van-pull-refresh__track {
    min-height: calc(100vh - 46px);
}

.Buy {
    overflow: auto;
    background: $background;

    .data {
        width: 100%;
        overflow: hidden;
        box-sizing: border-box;

        .drag-container {
            box-sizing: border-box;
            padding: h(1) h(12) h(15);
        }
    }

    .item {
        position: relative;
        margin-top: h(12);
        box-sizing: border-box;
        padding: h(25) w(15) h(20) w(17);
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        border-radius: 8px;

        .split {
            height: h(20);
            position: absolute;
            top: 0;
            right: 0;
        }

        .left {
            display: flex;
            flex-flow: row nowrap;

            .head {
                width: h(52);
                border-radius: 50%;
                overflow: hidden;
                margin-right: w(12);

                img {
                    width: h(52);
                    height: h(52);
                }
            }

            .money {
                .num {
                    font-size: 18px;
                    font-weight: bold;
                    color: #000;

                    & span:first-child {
                        margin-right: w(3);
                        color: #6d88fb;
                    }
                }

                .bank {
                    font-size: 12px;

                    span {
                        display: inline-block;
                        padding: h(3) w(6);
                        text-align: center;
                        margin-right: w(3);
                        border-radius: 4px;
                    }

                    .yh {
                        background: rgba(255, 0, 0, 0.05);
                        color: #ff0000;
                    }
                    .wx {
                        background: rgba(63, 204, 87, 0.05);
                        color: #3fcc57;
                    }
                    .zfb {
                        background: rgba(52, 147, 255, 0.05);
                        color: #3493ff;
                    }
                }
            }
        }

        .right {
            span {
                margin-top: h(5);
                color: #3366ee;
                border: 1px solid #3366ee;
                border-radius: 37px;
                padding: 8px 12px;
            }
        }
    }

    .content {
        padding: 16px 16px h(30);
        text-align: left;

        p {
            margin: h(20) 0 h(10);
            font-size: 14px;
            line-height: 18px;
            color: #333333;
        }

        .pay {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;

            span {
                display: inline-block;
                padding: h(12) w(37);
                color: #333333;
                background: #f2f2f2;
                border-radius: 4px;
            }
        }

        .btn {
            margin-top: h(30);
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-around;

            .quxiao {
                background: #f2f2f2;
                color: #828282;
            }
        }
    }

    .toTop {
        position: fixed;
        right: h(10);
        bottom: h(10);
    }
}
</style>
